<template>
  <div class="th-mb-24">
    <h5
      class="th-m-0 body2"
      v-if="input.label"
      v-html="$translation.t(input.label)"
    ></h5>
    <v-form ref="newListItemForm">
      <div class="d-flex align-items-start">
        <template v-for="(subinput, subinputKey) in input.inputs">
          <v-autocomplete
            class="th-mx-4"
            v-if="subinput.type === 'select'"
            outlined
            :items="subinput.values.map((v) => $translation.t(v))"
            v-model="newItemModel[subinput.model]"
            auto-select-first
            :label="$translation.t(subinput.label)"
            hide-details="auto"
            :key="subinputKey"
            :rules="subinput.rules || []"
          >
          </v-autocomplete>
          <v-text-field
            class="th-mx-4"
            v-if="subinput.type === 'text'"
            :label="$translation.t(subinput.label)"
            outlined
            v-model="newItemModel[subinput.model]"
            hide-details="auto"
            :key="subinputKey"
            :rules="subinput.rules || []"
          >
          </v-text-field>
        </template>
        <v-btn
          color="primary"
          outlined
          size="large"
          @click="addOption"
          height="48"
          class="th-ml-12"
        >
          {{ $translation.t("Add") }}
        </v-btn>
      </div>
    </v-form>

    <div class="my-5" v-if="model.length > 0">
      <v-data-table
        :hide-default-footer="true"
        :headers="headers"
        :items="model"
        class="elevation-1"
      >
        <template v-slot:item.action="{ item }">
          <v-icon small @click="remove(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import rules from "@/utils/inputRules";

export default {
  name: "AddToListField",
  props: {
    input: {
      type: Object,
      required: true,
    },
    value: [Array, String],
  },
  data: () => ({
    rules,
    model: [],
    newItemModel: {},
    options: [],
    newOption: "",
  }),
  mounted() {
    this.model = this.value || [];
  },
  computed: {
    headers() {
      let headers = this.input.inputs.map(({ label, model }) => ({
        text: this.$translation.t(label),
        value: model,
      }));

      // Add Action
      headers.push({ text: "", value: "action", align: "end" });

      return headers;
    },
  },
  methods: {
    addOption() {
      if (this.$refs.newListItemForm.validate()) {
        // Apply Filters
        this.input.inputs.forEach((subinput) =>
          subinput.filters?.forEach(
            (filter) =>
              (this.newItemModel[subinput.model] = filter(
                this.newItemModel[subinput.model]
              ))
          )
        );

        this.model.push(this.newItemModel);

        this.reset();
        this.$refs.newListItemForm.reset();
      }
    },
    remove(item) {
      this.model = this.model.filter(
        (entry) =>
          Object.keys(item).filter((key) => entry[key] === item[key]).length ===
          0
      );
    },
    reset() {
      this.newItemModel = {};
    },
    validate() {
      this.addOption();
      return true;
    },
  },
  watch: {
    model: {
      handler: function (newInput) {
        this.$emit("input", newInput);
      },
      deep: true,
    },
  },
};
</script>
